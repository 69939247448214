import { Container, Nav,Navbar} from 'react-bootstrap';
import React from 'react';
import {
  NavLink
} from './NavbarElements'
import "../assets/styles/navbar.css";
const NavbarComponent = () => {
  return (
    <>
      <Navbar className='navbar' bg="transparent">
        <Container className="d-flex justify-content-center align-items-center">
          <Nav>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/contatti">Contatti</NavLink>
            <NavLink to="/gallery">Galleria</NavLink>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarComponent;