import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import "../assets/styles/contatti.css";
import ContattiComponentsDsk from "../components/ContattiComponents-dsk";
import ContattiComponentsMob from "../components/ContattiComponents-mob";
import NavbarComponent from "../components/Navbar-component";

const Contatti = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderComponentForWidth = (minWidth, maxWidth, componentInRange, componentOutsideRange) => {
    const isWithinRange = windowWidth >= minWidth && windowWidth <= maxWidth;
    return isWithinRange ? componentInRange : componentOutsideRange;
  };
  return (
    <div style={{overflowX:'hidden'}}>
      <NavbarComponent />
      <Row
        style={{
          fontFamily: "Cinzel",
          color: "white",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <span className="descriptionPage">Per qualsiasi domanda o ulteriori chiarimenti, siamo a tua disposizione. Contattaci!</span>
      </Row>
      <ContattiComponentsMob/>
    </div>
  );
};

export default Contatti;
