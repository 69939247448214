import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "../assets/styles/gallery.css";
import NavbarComponent from "../components/Navbar-component";
import SwiperComponent from "../components/Swiper-component";
import { Container } from "react-bootstrap";

function Gallery() {
  return (
    <>
      <NavbarComponent />
      <Container className="containerGallery">
        <p>Vivi un'esperienza visuale con il nostro tour di foto!</p>
        <SwiperComponent />
      </Container>
    </>
  );
}

export default Gallery;
