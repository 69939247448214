import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectCoverflow, Autoplay } from "swiper";



import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../assets/styles/gallery.css";
// Import Swiper styles

const SwiperComponent = () => {

  let items = [
    "bagno1",
    "bagno2",
    "bagno3",
    "bagno4",
    "camera1",
    "letto1",
    "letto2",
    "letto3",
    "letto4",
    "fuori1",
    "cucina1",
    "salone1",
    "salone2",
    "salone3",
    "salone4",
  ];
  let itemList = [];

  items.forEach((item, index) => {
    itemList.push(
      <SwiperSlide key={index}>
        <img src={require(`../assets/images/${item}.jpg`)} />
      </SwiperSlide>
    );
  });
  return (
    <Swiper
      style={{
        "--swiper-pagination-color": "#FFFFFF",
      }}
      effect={"coverflow"}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={"auto"}
      coverflowEffect={{
        rotate: 25,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: false,
      }}
      pagination={{
        clickable: true,
      }}
      modules={[EffectCoverflow, Pagination, Autoplay]}
      className="mySwiper"
    >
      {itemList}
    </Swiper>
  );
};

export default SwiperComponent;
