import React from "react";
import "./App.css";
function App() {

  return (
    <>
{/*       <NavbarComponent />
      <div className="centeredContainer">
        <p>
          La tua Esperienza inizia da qui !
        </p>
        <button
          onClick={() => navigateTo()}
          style={{ fontFamily: "Satisfy", fontSize: 30 }}
        >
          Esplora
        </button>
      </div> */}
    </>
  );
}

export default App;
