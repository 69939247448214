import "../assets/styles/home.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "../components/Navbar-component";

function Home() {
  const navigate = useNavigate();
  const navigateTo = () => navigate("/gallery");
  return (
    <>
      <NavbarComponent />
      <div className="centeredContainer">
        <img
          src={require(`../assets/images/il-parco.png`)}
          width="220"
          height="180"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"

        />
        <p>Inizia la tua avventura nel cuore di Lecce</p>
        <button
          onClick={() => navigateTo()}
          style={{ fontFamily: "Satisfy", fontSize: 30 }}
        >
          Esplora
        </button>
      </div>
    </>
  );
}

export default Home;
