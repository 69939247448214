import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faAt, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "../assets/styles/contatti.css";
import UserContacts from "./UserContact-component";


const CardComponent = (props) => {
  library.add(faInstagram, faFacebook, faPhone, faAt, faLocationDot, faWhatsapp);
  const onLocationDotClicked = () => {
    const url =
      "https://www.google.com/maps/place/Via+Ezio+Vanoni,+7,+73100+Masseria+Seminario+LE/@40.3867836,18.1655314,17z/data=!3m1!4b1!4m6!3m5!1s0x13442e9f99637d39:0x6c3b3262f5dcef3b!8m2!3d40.3867836!4d18.1677201!16s%2Fg%2F11m9kq5439?entry=ttu";
    window.open(url, "_blank", "noreferrer");
  };
  let mixedCard = [
    {
      id: 1,
      title: props.title[0],
      image: props.image || '',
      icon: faLocationDot,
      function: () => onLocationDotClicked()
    },
    {
      id: 2,
      image: props.image || '',
      icon: faPhone,
      text: 'Simona',
    },
    {
      id: 3,
      title: props.title[2],
      image: props.image || '',
      icon: faAt
    },
  ];
  let fakeList = [];

  mixedCard.forEach((item, index) => {
    fakeList.push(
      <Card style={{ maxWidth: 480, width: "90%", margin: 5 }} onClick={item.function}>
        <Row className="text-center mt-2">
          <FontAwesomeIcon icon={item.icon}></FontAwesomeIcon>
        </Row>
        <Card.Body className={'d-flex justify-content-center align-items-center flex-column'}>
          <span
            className="descriptionText"
          >
            {item.title}
          </span>
          {item.text ? <div style={{ display: 'flex', flexDirection: 'column', }}><UserContacts nome={'Simona'} numeroTel={'3292571772'} display={'flex'} /><UserContacts nome={'Gianni'} numeroTel={'3389276351'} display={'flex'} /><UserContacts nome={'Lorenzo'} numeroTel={'3245498093'} display={'flex'} /></div> : ''}
        </Card.Body>
      </Card>
    );
  });
  return (
    <div style={{ display: "flex", marginTop: 50, justifyContent: 'center', alignItems: 'center' }}>
      <Row className="d-flex justify-content-center align-items-center" style={{ maxWidth: 480 }}>
        {fakeList}
      </Row>
    </div>
  )
}

export default CardComponent;
