import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const UserContacts = (props) => {

    const openWhatsApp = (numeroTel) => {
        window.open(`https://wa.me/39${numeroTel}`, '_blank', 'noreferrer');
    }

    return (
        <Row onClick={() => openWhatsApp(props.numeroTel)} style={{gap:5, marginTop: 5}}>
            <Col style={{ maxWidth: '5%', cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faWhatsapp} size='xl' />
            </Col>
            <Col style={{ justifyContent: props.justify ? props.justify : '', alignItems: props.align ? props.align : '', display: props.display ? props.display : '' }}>
                <p style={{ cursor: 'pointer', fontFamily: 'Cinzel', fontSize: 15, color: '#333333', marginTop: 2}}>{props.nome} - {props.numeroTel}</p>
            </Col>
        </Row>
    );
}

export default UserContacts;