import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
  

export const NavLink = styled(Link)`
  color: #FFF;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-family: 'Azeret Mono';
  &:hover {
    color: #d9bb8f  !important;
  }
  &.active {
    text-decoration:underline;
  }
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
  font-style: italic;
  @media (max-width: 420px) {
    font-size: 20px;
  }
`;


